
export var ethnicity = {value: "--"};

export var bpm = {value: 0};

export var bpmType = {value: "systolic"};

export var prescription = {value: ""};



export const IMG = {
  RED: "./images/Red-tick.png",
  GREEN: "./images/Green-tick.png",
  AMBER: "./images/Amber-tick.png",

};

export var image = {value: IMG.GREEN}